import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function SEO() {
  const {site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title,
          description
        }
      }
    }
  `);
  return (
    <Helmet>
      <html lang="cs" />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="google-site-verification" content="6vzkBHL44yqqT1nXYU3aaJjbHSDd6GZoLlhanUAfZng" />
      <meta http-equiv="x-ua-compatible" content="ie=edge" />
      <meta name="description" content={site.siteMetadata.description} />
      <title>{site.siteMetadata.title}</title>
      <link rel="canonical" href="https://nejentepelnacerpadla.cz" />
      {/* Favicons */}
      <link rel="icon" href="./static/favicon.ico" type="image/x-icon" />
    </Helmet>
  )

}