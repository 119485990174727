import React, { useEffect, useState } from 'react';
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import useForm from '../utils/useForm'
import useEmail from '../utils/useEmail'
import SEO from '../components/SEO'
import scrollTo from 'gatsby-plugin-smoothscroll'


export default function Home({ data }) {
  const { values, updateValue, cleanValue } = useForm({
    name: '',
    email: '',
    subject: '',
    message: '',
    maple: '',
  })

  const [zoom, setZoom] = useState(false)
  const [focusField, setFocusField] = useState(null) // uchovat, ktery field ma focus
  const {loading, sendEmail } = useEmail(values, cleanValue)

  const zoomIn = () => {
    if (window.scrollY >= 5) {
      setZoom(true)
    } else {
      setZoom(false)
    }
  }




  useEffect(() => {
    document.addEventListener('scroll', zoomIn)
  })

  return (
    <>
      <SEO />
        <BackgroundImage
          className="hero"
          fluid={data.indexImage.childImageSharp.fluid}
        >
        <section id="home" className="d-flex align-items-center text-white position-relative vh-100 cover hero black-overlay">
          <div className="container-fluid container-fluid-max text-center">
            <div className="row">
              <div className="col-12">
                <h1
                className="mainHeader"
                >Vyřešíme vám pořízení a montáž nejen tepelných čerpadel.</h1>
                <h5 className="text-white pt-3">Návrh a montáž sestav tepelných čerpadel pro rodinné domy</h5>
                <h5 className="text-white pt-3">Samostatná tepelná čerpadla ke stávajícímu topení</h5>
                <h5 className="text-white pt-3">Dotace až 130 000 Kč</h5>
                <div className="mt-4">
                  <a className="btn bg-red text-white mr-2" onClick={() => scrollTo('#kontakt')} role="button">Zamluvit termín</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        </BackgroundImage>

        <div id="jaktofungujeAnch" className="pt-5"></div>
        <section id="jaktofunguje" className="process">
          <div className="container-fluid container-fluid-max">
            <div className="row text-center py-5">
              <div className="col-12 pb-4">
                <h2 className="text-red display-4">Česká tepelná čerpadla NeoRé - topení i chlazení</h2>
              <a className="btn bg-red text-white" href="https://www.neota.cz">
                Navštívit neota.cz
              </a>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 p-4">
                <h3 className="mt-3 text-red h2">TG MINI</h3> 
                <p>5-16kW, 35dB</p>
                <p className="font-weight-bold">Výbava a funkce:</p>
                <ul id="functionList" className="text-center">
                  <li>topení</li>
                  <li>chlazení</li>
                  <li>ekviterm - regulace teploty</li>
                  <li>ovládání inter/appka</li>
                </ul>
                <p><strong>Cena od: </strong> 159 900 Kč</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 p-4">
                <h3 className="mt-3 text-red h2">TG MINI +</h3>
                <p>5-16kW</p>
                <p className="font-weight-bold">Výbava a funkce:</p>
                  <ul id="functionList" className="text-center">
                    <li>topení</li>
                    <li>chlazení</li>
                    <li>ekviterm - regulace teploty</li>
                    <li>ovládání inter/appka</li>
                    <li>elektrokotel</li>
                 </ul>
                <p><strong>Cena od: </strong> 170 900 Kč</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 p-4">
                <h3 className="mt-3 text-red h2">TG</h3>
                <p>5-16kW</p>
                <p className="font-weight-bold">Výbava a funkce:</p>
                  <ul id="functionList" className="text-center">
                    <li>topení</li>
                    <li>chlazení</li>
                    <li>ekviterm - regulace teploty</li>
                    <li>ovládání inter/appka</li>
                    <li>elektrokotel</li>
                    <li>TUV</li>
                  </ul>
                <p><strong>Cena od: </strong> 182 900 Kč</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 p-4">
                <h3 className="mt-3 text-red h2">TG TX</h3>
                <p>5-16kW.</p>
                <p className="font-weight-bold">Výbava a funkce:</p>
                  <ul id="functionList" className="text-center">
                    <li>topení</li>
                    <li>chlazení</li>
                    <li>ekviterm - regulace teploty</li>
                    <li>ovládání inter/appka</li>
                    <li>elektrokotel</li>
                    <li>Vestavěný zas. TUV 200l</li>
                  </ul>
                <p><strong>Cena od: </strong> 243 900 Kč</p>
              </div>
              <div className="col-12 pt-3">
                <a
                  className="btn bg-red text-white mt-2"
                  onClick={() => scrollTo('#kontakt')}
                  role="button">Ozvěte se nám pro bezplatnou konzultaci →
                </a>
              </div>
            </div>
          </div>
        </section>

        <div id="detailyAnch" className="pt-5"></div>
        <section id="detaily" className="bg-lightblue">
          <div className="container-fluid container-fluid-max">
            <div className="row text-center py-5">
              <div className="col-12 pb-4">
                <h2 className="text-red display-4">Podlahové topení i chlazení</h2>
                <h3 className="">Systém podlahového topení Viega Fonterra</h3>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-2"></div>
              <div className="col-4 text-left">
                <p>rozdělovač podlahového topení: </p>
                <p>skříň pro rozdělovač: </p>
                <p>potrubí PB (polybuten 15*1,5): </p>
                <p>systémová deska 5cm:</p>
                <p>svěrná šroubení</p>
                <p>napuštění systému upravenou vodou: </p>
                <p>nastavení a vyregulování průtokům k požadavkům domu a tepelného čerpadla</p>
              </div>
              <div className="col-6 text-left">
                <p>nerezový s průtokoměry</p>
                <p>pozinkovaná lakovaná skříň</p>
                <p>nejvyšší hodnota přenosu tepla a kvalita</p>
                <p>vysoký kročejový útlum, přesná montáž</p>
                <br></br>
                <p>prodlouží životnost TČ a celého systému</p>
              </div>
            </div>
            <div className="row text-center pb-5 pt-5">
            <div className="col-2"></div>
              <div className="col-4 text-left">
                <p>Cena za kompletní podlahové topení včetně montáže 1m2: </p>
              </div>
              <div className="col-6 text-left">
                <p className="text-red"><b>923 Kč</b></p>
              </div>
            </div>
          </div>
        </section>

        <div id="ukazkyAnch" className="pt-5"></div>
        <section id="ukazky">
          <div className="container-fluid">
            <div className="row py-5">
              <div className="col-12 pb-4">
                <h2 className="text-red display-4 text-center">Příklady montáže z předchozího roku:</h2>
              </div>
              <div className="row seznamUkazek">
                {/* prvni karta */}
                <div className="col-sm-12 col-lg-6 moje-tile">
                  <h4>Rodinný dům patrový s celkovou tepelnou ztrátou 9 kW o celkové ploše 180 m², 4 osoby</h4>
                  <p>
                  <strong>Požadavek:</strong> topení i chlazení pomocí tepelného čerpadla a podlahového topení ohřev teplé vody v zásobníku 300 l
                  </p>
                  <p>
                  <strong>Řešení:</strong> kompletní vytápění i v těch nejhorších mrazech a chlazení v letních horkých dnech včetně ohřevu teplé vody
                  </p>
                  <div className="row pt-3">
                    <div className="col-6">
                      <p>Podlahové topení včetně přívodů a napojení na TČ:</p>
                    </div>
                    <div className="col-6">
                      <p>181 140 Kč</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Strojovna, včetně montáže a zásobníku TUV:</p>
                    </div>
                    <div className="col-6">
                      <p>263 920 Kč</p>
                    </div>
                  </div>
                  <hr class="solid"></hr>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Konečná cena bez DPH: </p>
                    </div>
                    <div className="col-6">
                      <p>445 060 Kč</p>
                    </div>

                  </div>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Celková cena i s 15% DPH: </p>
                    </div>
                    <div className="col-6">
                      <p className="text-red">511 819 Kč</p>
                    </div>
                  </div>
                </div>
                {/* druha karta */}
                <div className="col-sm-12 col-lg-6 moje-tile">
                  <h4>Rodinný dům bungalov s celkovou tepelnou ztrátou 6 kW o celkové ploše 108m², 4 osoby</h4>
                  <p>
                  <strong>Požadavek:</strong> topení i chlazení pomocí tepelného čerpadla a podlahového topení ohřev teplé vody v zásobníku 300 l
                  </p>
                  <p>
                  <strong>Řešení:</strong> kompletní vytápění i v těch nejhorších mrazech a chlazení v letních horkých dnech včetně ohřevu teplé vody.
                  </p>
                  <div className="row pt-3">
                    <div className="col-6">
                      <p>Podlahové topení včetně přívodů a napojení na TČ:</p>
                    </div>
                    <div className="col-6">
                      <p>114 684 Kč</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Strojovna, včetně montáže a zásobníku TUV:</p>
                    </div>
                    <div className="col-6">
                      <p>231 620 Kč</p>
                    </div>
                  </div>
                  <hr class="solid"></hr>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Konečná cena bez DPH: </p>
                    </div>
                    <div className="col-6">
                      <p>346 304 Kč</p>
                    </div>

                  </div>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Celková cena i s 15% DPH: </p>
                    </div>
                    <div className="col-6">
                      <p className="text-red">398 250 Kč</p>
                    </div>
                  </div>
                </div>
                {/* treti karta */}
                <div className="col-sm-12 col-lg-6 moje-tile">
                  <h4>Rodinný dům bungalov s celkovou tepelnou ztrátou 8 kW o celkové ploše 103m², 4 osoby</h4>
                  <p>
                  <strong>Požadavek:</strong> výměna starého elektrokotle za tepelné čerpadlo - topení i chlazení stávajícím podlahovím topením s ohřev teplé vody v zásobníku 300 l
                  </p>
                  <p>
                  <strong>Řešení:</strong> kompletní vytápění i v těch nejhorších mrazech a chlazení v letních horkých dnech včetně ohřevu teplé vody.
                  </p>
                  <div className="row pt-3">
                    <div className="col-6">
                      <p>Upravení přívodů pro TČ přívodů a napojení na TČ</p>
                    </div>
                    <div className="col-6">
                      <p>21 753 Kč</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Strojovna, včetně montáže a zásobníku TUV</p>
                    </div>
                    <div className="col-6">
                      <p>231 620 Kč</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Demontáž stávajícího zařízení</p>
                    </div>
                    <div className="col-6">
                      <p>10 000 Kč</p>
                    </div>
                  </div>
                  <hr class="solid"></hr>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Konečná cena bez DPH: </p>
                    </div>
                    <div className="col-6">
                      <p>263 373 Kč</p>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Celková cena i s 15% DPH: </p>
                    </div>
                    <div className="col-6">
                      <p className="text-red">302 879  Kč</p>
                    </div>
                  </div>
                </div>
                {/* ctvrta karta */}
                <div className="col-sm-12 col-lg-6 moje-tile">
                  <h4>Rodinný dům bungalov s celkovou tepelnou ztrátou 7 kW o celkové ploše 108m², 4 osoby</h4>
                  <p>
                  <strong>Požadavek:</strong> topení i chlazení pomocí tepelného čerpadla a stropního topení ohřev teplé vody v zásobníku 300L.
                  </p>
                  <p>
                  <strong>Řešení:</strong> kompletní vytápění i v těch nejhorších mrazech a chlazení v letních horkých dnech včetně ohřevu teplé vody.
                  </p>
                  <div className="row pt-3">
                    <div className="col-6">
                      <p>Stropní topení včetně přívodů a napojení na TČ</p>
                    </div>
                    <div className="col-6">
                      <p>216 000 Kč</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p>Strojovna, včetně montáže a zásobníku TUV</p>
                    </div>
                    <div className="col-6">
                      <p>231 620 Kč</p>
                    </div>
                  </div>
                  <hr class="solid"></hr>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Konečná cena bez DPH: </p>
                    </div>
                    <div className="col-6">
                      <p>447 620 Kč</p>
                    </div>

                  </div>
                  <div className="row text-center">
                    <div className="col-6">
                      <p>Celková cena i s 15% DPH: </p>
                    </div>
                    <div className="col-6">
                      <p className="text-red">514 763 Kč</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="kontakt" className="py-5 request-quote bg-lightblue">
          <div className="wrap">
            <div className="container">
              <form
                className="cool-b4-form"
                onSubmit={sendEmail}
              >
              <h2 className="text-center pt-4">Ozvěte se nám</h2>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        onFocus={() => setFocusField("name")}
                        onBlur={() => setFocusField(null)}
                        type="text"
                        // className="form-control"
                        className={`form-control ${
                          focusField === "name" || values.name
                            ? "hasValue"
                            : ""
                          }`
                        }
                        name="name"
                        value={values.name}
                        onChange={updateValue}
                        disabled={loading}
                        required
                      />
                      <label htmlFor="name">Jméno</label>
                      <span className="input-highlight"></span>
                    </div>
                    <div className="form-group">
                      <input type="email"
                        className={`form-control ${
                          focusField === "email" || values.email
                            ? "hasValue"
                            : ""
                          }`
                        }
                        name="email"
                        id="email"
                        required
                        value={values.email}
                        disabled={loading}
                        onChange={updateValue}
                        onFocus={() => setFocusField("email")}
                        onBlur={() => setFocusField(null)}
                      />
                      <label htmlFor="email">Email</label>
                      <span className="input-highlight"></span>
                    </div>
                    <input type="maple"
                      className="maple"
                      name="maple"
                      id="maple"
                      value={values.maple}
                      disabled={loading}
                      onChange={updateValue}
                    />
                    <div className="form-group">
                      <input type="text"
                        className={`form-control ${
                          focusField === "subject" || values.subject
                            ? "hasValue"
                            : ""
                          }`
                        }
                        name="subject"
                        id="subject"
                        value={values.subject}
                        onChange={updateValue}
                        disabled={loading}
                        onFocus={() => setFocusField("subject")}
                        onBlur={() => setFocusField(null)}
                        required
                      />
                      <label htmlFor="subject">Předmět</label>
                      <span className="input-highlight"></span>
                    </div>
                  </div>


                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className={`form-control ${
                          focusField === "message" || values.message
                            ? "hasValue"
                            : ""
                          }`
                        }
                        value={values.message}
                        onFocus={() => setFocusField("message")}
                        onBlur={() => setFocusField(null)}
                        onChange={updateValue}
                        disabled={loading}
                        required
                      ></textarea>
                      <label htmlFor="message">Vaše zpráva</label>
                      <span className="input-highlight"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 mx-auto mt-3">
                  <button
                    type="submit"
                    className="btn btn-lg btn-block"
                    disabled={loading}>
                    {loading ? 'Odesilam zpravu...' : 'Odeslat'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <footer className="py-5">
          <div className="container-fluid container-fluid-max text-center">
              <div className="row">
                <div className="col-md-12">
                  <div className="flex-center">
                    {/* Facebook */}
                    <a className="fb-ic" href="https://www.facebook.com/tzbwimmer/">
                      <FaFacebookSquare className="fa-facebook" size={"5em"} />
                      {/* <i className="fab fa-facebook fa-lg white-text fa-4x"> </i> */}
                    </a>
                    {/* Linkedin */}
                    <a className="li-ic" href="https://www.linkedin.com/in/luk%C3%A1%C5%A1-wimmer-8166059a/">
                      <FaLinkedin className="fa-linkedin" size={"5em"} />
                      {/* <i className="fab fa-linkedin fa-lg white-text fa-4x"> </i> */}
                    </a>
                  </div>
                </div>
              </div>
              <div className="text-center pt-3">
                <div className="copyright">
                  © 2021 Copyright: TZB-Wimmer s.r.o.
                </div>
            </div>
        </div>
      </footer>
    </>
  )
}

export const pageQuery = graphql`
  query {
    indexImage: file(relativePath: { eq: "myHero3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`