import { useState } from 'react'

export default function useEmail(values, cleanValue) {
  // 1. Create state that holds form data
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [message] = useState('');


  async function sendEmail(e) {

    e.preventDefault();
    setLoading(true);
    setError(null);

    // gather all the data
    const body = {
      name: values.name,
      email: values.email,
      maple: values.maple,
      subject: values.subject,
      message: values.message
    };
    // 4. Send this data the a serevrless function when they check out
    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/sendEmail`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
    const text = await res.text();

    // check if everything worked
    if (res.status >= 400 && res.status < 600) {
      setLoading(false); // turn off loading
      setError(text.message);
    } else {
      setLoading(false);
      const requiredFields = ['name', 'email', 'subject', 'message']

      requiredFields.map((field) => {
        let cleaningObj = {
          target: {
            name: field,
            value: '',
            type: 'string',
          }
        }
        cleanValue(cleaningObj)
      })
    }
  }

  return {
    error,
    loading,
    message,
    sendEmail,
  };
}